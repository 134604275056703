<template>
  <div>
    <div>
      <k-header-section
        header-text="Transactions"
        :item-label="`${title} Transactions`"
        :sub-nav-bar-items="navDatas"
        :is-enable-create="false"
        :total-items-count="totalItemsCount"
        :current-page-count="currentPageCount"
        @on-click-create="onCreateTransaction"
      />
    </div>
    <div>
      <router-view
        @update-total-count="updateTotalItems"
      />
    </div>
  </div>
</template>

<script>
import { KHeaderSection } from '@kingpin-global/kingpin-ui'
import transactionsNavBarContent from '@/assets/app-content/transactions-navbar-content.json'
import { capitalize } from '@/@core/utils/format'
import {
  SET_COMPONENT,
  SET_COMPONENT_CONTENT,
  SET_FORM_ACTIONS,
  SET_TITLE,
  TOGGLE_SIDEBAR,
} from '@/store/modules/sidebar.module'
import CreateTransaction from './CreateTransaction.vue'
import HeaderColumns from '../filter/HeaderColumns.vue'

export default {
  name: 'Transactions',
  components: {
    KHeaderSection,
  },
  data() {
    return {
      navDatas: transactionsNavBarContent.navBarItems,
      currentPageCount: 0,
      totalItemsCount: 0,
    }
  },
  computed: {
    transactionType() {
      return this.$route.path.split('/').pop()
    },
    title() {
      return capitalize(this.transactionType.slice(0, -1))
    },
    tableItems() {
      const { fromItem, toItem } = this.$store.state[this.transactionType]
      return this.$store.getters[`${this.transactionType}Table`](
        fromItem,
        toItem,
      )
    },
  },
  methods: {
    onSetEditColumns() {
      this.$store.commit(SET_TITLE, 'Edit Columns')
      this.$store.commit(SET_COMPONENT, HeaderColumns)
      this.$store.commit(SET_COMPONENT_CONTENT, this.componentContent)
      this.$store.commit(SET_FORM_ACTIONS, this.editColformActions)
      this.toggleSidebar()
    },
    onCreateTransaction() {
      this.$store.commit(SET_TITLE, 'Create New Transaction')
      this.$store.commit(SET_COMPONENT, CreateTransaction)
      this.$store.commit(SET_FORM_ACTIONS, this.formActions)
      this.toggleSidebar()
    },
    updateHeaderColumns() {
      const headerColMap = this.componentContent.headerColumns.reduce(
        (acc, col) => {
          acc[col.key] = col.value
          return acc
        },
        {},
      )
      this.fields = this.fields.map(field => {
        if (field.key in headerColMap) {
          return { ...field, value: headerColMap[field.key] }
        }
        return field
      })
    },

    editColformActions(actionType) {
      switch (actionType) {
        case 'submit':
          this.updateHeaderColumns()
          break
        case 'cancel':
          // Emit function for  display columns in table yet to implement
          break
        default:
          this.toggleSidebar()
      }
    },
    toggleSidebar() {
      this.$store.commit(TOGGLE_SIDEBAR)
    },
    saveTxn() {
      // Write form submit function here ...
      this.toggleSidebar()
    },
    formActions(actionType) {
      switch (actionType) {
        case 'submit':
          this.saveTxn()
          break
        case 'cancel':
        default:
          this.toggleSidebar()
      }
    },
    updateTotalItems(currentPageCount, totalItemsCount) {
      this.currentPageCount = currentPageCount
      this.totalItemsCount = totalItemsCount
    },
  },
}
</script>
