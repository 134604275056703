<template>
  <validation-observer
    ref="createTxnForm"
    #default="{ invalid }"
  >
    <b-form
      class="create-txn-form"
      @submit.prevent="$emit('dynamic-emits', 'submit')"
    >
      <validation-provider
        #default="{ errors }"
        name="Status"
        vid="status"
        :rules="required"
      >
        <k-form-group
          :form-invalid-content="errors[0]"
          :state="errors.length > 0 ? false : null"
          label-for="status"
        >
          <template #label>
            Status <span class="text-danger">*</span>
          </template>
          <b-dropdown
            class="w-100 mt-1 k-search-dropdown dropdown-custom-class"
            variant="none"
            toggle-class="drop-caret-icon"
            :text="TRANSACTION_STATUS[txnModel.status] || 'Select a status'"
          >
            <b-dropdown-item
              v-for="option in Object.keys(TRANSACTION_STATUS)"
              :key="option"
              :active="txnModel.status === option"
              @click="txnModel.status = option"
            >
              {{ TRANSACTION_STATUS[option] }}
            </b-dropdown-item>
          </b-dropdown>
        </k-form-group>
      </validation-provider>
      <validation-provider
        #default="{ errors }"
        name="Amount Due"
        vid="amountDue"
        :rules="required"
      >
        <k-form-group
          class="mt-2"
          :form-invalid-content="errors[0]"
          :state="errors.length > 0 ? false : null"
          label-for="amount-due"
        >
          <template #label>
            Amount Due <span class="text-danger">*</span>
          </template>

          <k-form-input
            v-model="txnModel.amountDue"
            type="text"
            name="amount-due"
            placeholder="Type a reference"
            :state="errors.length > 0 ? false : null"
            autocomplete="off"
          />
        </k-form-group>
      </validation-provider>
      <validation-provider
        #default="{ errors }"
        name="Due Date"
        vid="dueDate"
        :rules="required"
      >
        <k-form-group
          class="mt-2"
          :form-invalid-content="errors[0]"
          :state="errors.length > 0 ? false : null"
          label-for="due-date"
        >
          <template #label>
            Due Date <span class="text-danger">*</span>
          </template>
          <k-form-input
            v-model="txnModel.dueDate"
            type="text"
            name="due-date"
            placeholder="Due Date"
            :state="errors.length > 0 ? false : null"
          />
        </k-form-group>
      </validation-provider>
      <validation-provider
        #default="{ errors }"
        name="Type"
        vid="type"
        :rules="required"
      >
        <k-form-group
          class="mt-2"
          :form-invalid-content="errors[0]"
          :state="errors.length > 0 ? false : null"
          label-for="type"
        >
          <template #label>
            Type <span class="text-danger">*</span>
          </template>
          <b-dropdown
            class="w-100 mt-1 k-search-dropdown dropdown-custom-class"
            variant="none"
            toggle-class="drop-caret-icon"
            :text="txnModel.type || `Select a transaction's type`"
          >
            <b-dropdown-item
              v-for="option in Object.keys(TRANSACTION_TYPES)"
              :key="option"
              :active="txnModel.type === TRANSACTION_TYPES[option]"
              @click="txnModel.type = TRANSACTION_TYPES[option]"
            >
              {{ TRANSACTION_TYPES[option] }}
            </b-dropdown-item>
          </b-dropdown>
        </k-form-group>
      </validation-provider>
      <k-document-input :file-list="txnModel.documentList" />
      <validation-provider
        #default="{ errors }"
        name="Reference"
        vid="reference"
        :rules="required"
      >
        <k-form-group
          class="mt-2"
          :form-invalid-content="errors[0]"
          :state="errors.length > 0 ? false : null"
          label-for="reference"
        >
          <template #label>
            Reference <span class="text-danger">*</span>
          </template>
          <b-form-textarea
            v-model="txnModel.notes"
            name="Reference"
            placeholder="Add a note"
          />
        </k-form-group>
      </validation-provider>
      <div class="d-flex flex-row">
        <k-button
          variant="info"
          type="submit"
          :disabled="invalid || loading"
        >
          CREATE
          <feather-icon
            v-if="loading"
            icon="LoaderIcon"
          />
        </k-button>
        <k-button
          class="ms-4"
          variant="outline-info"
          :disabled="loading"
          @click="$emit('dynamic-emits', 'cancel')"
        >
          CANCEL
        </k-button>
      </div>
    </b-form>
  </validation-observer>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm,
  BDropdown,
  BDropdownItem,
  BFormTextarea,
} from 'bootstrap-vue'
import {
  KDocumentInput, KButton, KFormGroup, KFormInput,
} from '@kingpin-global/kingpin-ui'
import { required } from '@/@core/utils/validations/validations'
import '@fontsource/work-sans'
import '@fontsource/inter'
import { TRANSACTION_STATUS, TRANSACTION_TYPES } from '@/constants'

export default {
  name: 'CreateTransaction',
  components: {
    BForm,
    KButton,
    BDropdown,
    BDropdownItem,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    KDocumentInput,
    KFormGroup,
    KFormInput,
  },
  data() {
    return {
      loading: false,
      txnModel: {
        status: '',
        amountDue: '',
        dueDate: '',
        type: '',
        documents: '',
        notes: '',
      },
      TRANSACTION_STATUS,
      TRANSACTION_TYPES,
    }
  },
  computed: {
    required() {
      return required
    },
  },
  emits: ['dynamic-emits'],
}
</script>

<style lang="scss">
@import "../../assets/scss/variables/_variables-components.scss";
@import "../../assets/scss/kingpin-style.scss";
.create-txn-form {
  margin-top: 3%;
  padding: 0% $side-bar-form-padding;
}
</style>
